import React, { useEffect } from 'react'
import { ResponsiveBar } from '@nivo/bar'

const TopLanguagesChart = React.memo(({ data }) => {
  const [sortedData, setSortedData] = React.useState([])

  useEffect(() => {
    const languageMap = {
      en: 'English',
      others: 'Others',
    }

    const processedData = Object.entries(data)
      .map(([lang, details]) => ({
        name: languageMap[lang] || lang.toUpperCase(),
        percentage: details.percentage,
        weight: details.weight,
        estimated_clicks: details.estimated_clicks,
      }))
      .sort((a, b) => b.percentage - a.percentage)

    setSortedData([...processedData].reverse())
  }, [data])

  return (
    <div
      className='bg-white text-slate-900 border border-slate-200 p-6 rounded-lg shadow-sm w-full'
      style={{ height: '187px' }}
    >
      <h3 className='text-lg font-semibold text-slate-900'>Top Languages</h3>
      <ResponsiveBar
        data={sortedData}
        keys={['percentage']}
        animate={true}
        indexBy='name'
        margin={{ top: 0, right: 60, bottom: 50, left: 85 }}
        padding={0.3}
        layout='horizontal'
        colors={'#2563EB'}
        labelTextColor='transparent'
        labelSkipWidth={999}
        labelSkipHeight={999}
        maxValue={100}
        borderRadius={6}
        axisTop={null}
        axisRight={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          tickColor: '#64748B',
          format: (value) => {
            const entry = sortedData.find((d) => d.name === value)
            return entry ? `${entry.percentage.toFixed(2)}%` : ''
          },
        }}
        axisBottom={null}
        enableGridY={false}
        theme={{
          tooltip: {
            container: {
              background: '#2563EB',
              color: 'white',
              fontSize: 14,
              borderRadius: 4,
              padding: '6px 12px',
            },
          },
          labels: {
            text: {
              fontSize: 14,
              fill: '#334155',
            },
          },
          axis: {
            ticks: {
              text: {
                fill: '#334155',
                fontSize: 14,
              },
            },
          },
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          renderTick: (tick) => (
            <text x={tick.x - 80} y={tick.y + 5} fill='#334155' fontSize='14'>
              {tick.value}
            </text>
          ),
        }}
        tooltip={({ indexValue, value }) => {
          const entry = sortedData.find((d) => d.name === indexValue)

          return (
            <div className='bg-white text-slate-900 text-sm px-3 py-1 rounded shadow-md border border-slate-300 flex flex-col justify-center items-center'>
              <strong>{indexValue}</strong>
              <div> Percentage: {value.toFixed(2)}%</div>
              <div> Weight: {entry.weight.toFixed(4)}</div>
              <div> Clicks: {entry.estimated_clicks}</div>
            </div>
          )
        }}
        layers={[
          (props) => (
            <>
              {props.bars.map((bar) => (
                <g key={bar.key}>
                  <rect
                    x={bar.x}
                    y={bar.y}
                    width={props.innerWidth}
                    height={bar.height}
                    className='fill-slate-100'
                    rx={6}
                    ry={6}
                  />
                </g>
              ))}
            </>
          ),
          'grid',
          'axes',
          'bars',
          'markers',
          'legends',
          'annotations',
        ]}
      />
    </div>
  )
})

export default TopLanguagesChart
