import React, { useState, useEffect, useMemo } from 'react'
import CountryRegionsChart from './components/Analytics/CountryRegionsChart'
import TopDaysChart from './components/Analytics/TopDaysChart'
import TopLanguagesChart from './components/Analytics/TopLanguagesChart'
import TopDevicesChart from './components/Analytics/TopDevicesChart'
import TopPlatformsChart from './components/Analytics/TopPlatformsChart'
import TopBrowsersChart from './components/Analytics/TopBrowsersChart'
import SmsAnalyticsChart from './components/Analytics/SmsAnalyticsChart'

const CampaignAnalyticsData = ({ analytics, campaignId, sentCustomers }) => {
  const [isUpdating, setIsUpdating] = useState(false)
  const [lastUpdateTime, setLastUpdateTime] = useState(null)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [timeLeft, setTimeLeft] = useState(0)

  useEffect(() => {
    // Установка начального состояния кнопки
    const lastUpdate = localStorage.getItem(
      `campaign_${campaignId}_last_update`
    )
    if (lastUpdate) {
      const timeSinceLastUpdate = Date.now() - parseInt(lastUpdate)
      if (timeSinceLastUpdate < 60000) {
        // 1 минута в миллисекундах
        setButtonDisabled(true)
        setTimeLeft(Math.ceil((60000 - timeSinceLastUpdate) / 1000))
      }
    }
  }, [campaignId])

  useEffect(() => {
    let timer
    if (timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            setButtonDisabled(false)
            return 0
          }
          return prev - 1
        })
      }, 1000)
    }
    return () => clearInterval(timer)
  }, [timeLeft])

  const handleUpdateAnalytics = async () => {
    setIsUpdating(true)
    setButtonDisabled(true)

    // Создаем контроллер для отмены запроса
    const controller = new AbortController()
    const timeoutId = setTimeout(() => controller.abort(), 25000) // 25 секунд таймаут

    try {
      const response = await fetch(
        `/api/v1/campaigns/${campaignId}/update_analytics`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('[name="csrf-token"]')
              .content,
          },
          signal: controller.signal // Добавляем сигнал для возможности отмены запроса
        }
      )

      clearTimeout(timeoutId) // Очищаем таймер если запрос завершился успешно

      if (response.ok) {
        localStorage.setItem(
          `campaign_${campaignId}_last_update`,
          Date.now().toString()
        )
        setTimeLeft(60)
        window.location.reload()
      } else {
        throw new Error('Failed to update analytics')
      }
    } catch (error) {
      clearTimeout(timeoutId) // Очищаем таймер в случае ошибки
      
      if (error.name === 'AbortError') {
        console.error('Request timed out after 25 seconds')
        alert('Request timed out. Please try again later.')
      } else {
        console.error('Error updating analytics:', error)
        alert('Failed to update analytics. Please try again later.')
      }
      setButtonDisabled(false)
    } finally {
      setIsUpdating(false)
    }
  }

  const memoizedCountryAnalytics = useMemo(
    () => analytics.by_region,
    [analytics]
  )

  const memoizedTopDaysAnalytics = useMemo(
    () => analytics.by_weekday,
    [analytics]
  )

  const memoizedTopLanguagesAnalytics = useMemo(
    () => analytics.by_language,
    [analytics]
  )

  const memoizedTopDevicesAnalytics = useMemo(
    () => analytics.by_device,
    [analytics]
  )

  const memoizedTopPlatformsAnalytics = useMemo(
    () => analytics.by_platform,
    [analytics]
  )

  const memoizedTopBrowsersAnalytics = useMemo(
    () => analytics.by_browser,
    [analytics]
  )

  if (!analytics) {
    return (
      <div className='bg-white rounded-xl shadow-sm border border-slate-200 p-6'>
        <div className='text-slate-500 text-center'>
          No analytics data available for this campaign
        </div>
      </div>
    )
  }

  return (
    <div className='bg-white rounded-xl shadow-sm border border-slate-200 p-6'>
      <div className='flex justify-between items-center mb-4'>
        <h3 className='text-lg font-semibold text-slate-900'>
          Campaign Analytics Data
        </h3>
        <button
          onClick={handleUpdateAnalytics}
          disabled={buttonDisabled || isUpdating}
          className={`inline-flex items-center px-4 py-2 rounded-lg text-sm font-medium transition-colors
            ${
              buttonDisabled || isUpdating
                ? 'bg-slate-100 text-slate-400 cursor-not-allowed'
                : 'bg-blue-600 text-white hover:bg-blue-700'
            }`}
        >
          {isUpdating ? (
            <>
              <svg
                className='animate-spin -ml-1 mr-2 h-4 w-4 text-current'
                fill='none'
                viewBox='0 0 24 24'
              >
                <circle
                  className='opacity-25'
                  cx='12'
                  cy='12'
                  r='10'
                  stroke='currentColor'
                  strokeWidth='4'
                />
                <path
                  className='opacity-75'
                  fill='currentColor'
                  d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                />
              </svg>
              Updating...
            </>
          ) : buttonDisabled ? (
            <>
              <svg
                className='mr-2 h-4 w-4'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z'
                />
              </svg>
              Wait {timeLeft}s
            </>
          ) : (
            <>
              <svg
                className='mr-2 h-4 w-4'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15'
                />
              </svg>
              Update Analytics
            </>
          )}
        </button>
      </div>
      <div className='bg-slate-50 rounded-lg p-4 font-mono text-sm overflow-x-auto'>
        <pre className='whitespace-pre-wrap flex flex-col gap-4 overflow-hidden'>
          {!!analytics.total_clicks && (
            <SmsAnalyticsChart
              analytics={analytics}
              sentCustomers={sentCustomers}
            />
          )}

          {analytics.by_region &&
            Object.keys(analytics.by_region).length > 0 && (
              <CountryRegionsChart data={memoizedCountryAnalytics} />
            )}

          <div className='flex gap-4 w-full'>
            <div className='flex gap-4 w-full flex-col'>
              {analytics.by_region &&
                Object.keys(analytics.by_region).length > 0 && (
                  <TopDevicesChart data={memoizedTopDevicesAnalytics} />
                )}
              {analytics.by_weekday &&
                Object.keys(analytics.by_weekday).length > 0 && (
                  <TopPlatformsChart data={memoizedTopPlatformsAnalytics} />
                )}
              {analytics.by_language &&
                Object.keys(analytics.by_language).length > 0 && (
                  <TopLanguagesChart data={memoizedTopLanguagesAnalytics} />
                )}
            </div>

            <div className='flex gap-4 w-full flex-col'>
              {analytics.by_weekday &&
                Object.keys(analytics.by_weekday).length > 0 && (
                  <TopDaysChart data={memoizedTopDaysAnalytics} />
                )}

              {analytics.by_device &&
                Object.keys(analytics.by_device).length > 0 && (
                  <TopBrowsersChart data={memoizedTopBrowsersAnalytics} />
                )}
            </div>
          </div>
        </pre>
      </div>
      <div className='mt-4 text-xs text-slate-500'>
        Last updated: {new Date(analytics.last_update).toLocaleString()}
      </div>
    </div>
  )
}

export default CampaignAnalyticsData
