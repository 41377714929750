import React, { useEffect, useState } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { Monitor } from 'lucide-react'
import { browserMap } from './Helpers'

const TopBrowsersChart = React.memo(({ data }) => {
  const [showAll, setShowAll] = useState(false)
  const [sortedData, setSortedData] = useState([])

  useEffect(() => {
    const processedData = Object.entries(data)
      .map(([browser, details]) => ({
        name:
          browserMap[browser]?.name ||
          browser.replace(/-/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase()),
        icon: browserMap[browser]?.icon || (
          <Monitor size={16} className='inline-block mr-2 text-gray-600' />
        ),
        percentage: details.percentage,
        weight: details.weight,
        estimated_clicks: details.estimated_clicks,
      }))
      .sort((a, b) => b.percentage - a.percentage)

    setSortedData(processedData)
  }, [data])

  const displayedData = showAll
    ? [...sortedData].reverse()
    : [...sortedData.slice(0, 5)].reverse()

  return (
    <div
      className='bg-white text-slate-900 border border-slate-200 p-6 rounded-lg shadow-sm w-full'
      style={{ height: showAll ? '650px' : '300px' }}
    >
      <div className='flex justify-between items-center'>
        <h3 className='text-lg font-semibold text-slate-900'>Top Browsers</h3>
        <button
          onClick={() => setShowAll(!showAll)}
          className='bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700'
        >
          {showAll ? 'Hide' : 'Show All'}
        </button>
      </div>
      <ResponsiveBar
        data={displayedData}
        keys={['percentage']}
        animate={true}
        indexBy='name'
        margin={{ top: 0, right: 60, bottom: 50, left: 220 }}
        padding={0.3}
        layout='horizontal'
        colors={'#2563EB'}
        labelTextColor='transparent'
        labelSkipWidth={999}
        labelSkipHeight={999}
        maxValue={100}
        borderRadius={6}
        axisTop={null}
        axisRight={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          tickColor: '#64748B',
          format: (value) => {
            const entry = displayedData.find((d) => d.name === value)
            return entry ? `${entry.percentage.toFixed(2)}%` : ''
          },
        }}
        axisBottom={null}
        enableGridY={false}
        theme={{
          tooltip: {
            container: {
              background: '#2563EB',
              color: 'white',
              fontSize: 14,
              borderRadius: 4,
              padding: '6px 12px',
            },
          },
          labels: {
            text: {
              fontSize: 14,
              fill: '#334155',
            },
          },
          axis: {
            ticks: {
              text: {
                fill: '#334155',
                fontSize: 14,
              },
            },
          },
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          renderTick: (tick) => {
            const entry = displayedData.find((d) => d.name === tick.value)
            return (
              <g transform={`translate(${tick.x - 200},${tick.y})`}>
                <foreignObject x='-20' y='-10' width='20' height='20'>
                  {entry?.icon}
                </foreignObject>
                <text
                  x='10'
                  y='0'
                  dy='4'
                  fill='#334155'
                  textAnchor='start'
                  fontSize='14'
                >
                  {tick.value}
                </text>
              </g>
            )
          },
        }}
        tooltip={({ indexValue, value }) => {
          const entry = displayedData.find((d) => d.name === indexValue)

          return (
            <div className='bg-white text-slate-900 text-sm px-3 py-1 rounded shadow-md border border-slate-300 flex flex-col justify-center items-center'>
              <strong className='ml-2'>{indexValue}</strong>
              <div> Percentage: {value.toFixed(2)}%</div>
              <div> Weight: {entry.weight.toFixed(4)}</div>
              <div> Clicks: {entry.estimated_clicks}</div>
            </div>
          )
        }}
        layers={[
          (props) => (
            <>
              {props.bars.map((bar) => (
                <g key={bar.key}>
                  <rect
                    x={bar.x}
                    y={bar.y}
                    width={props.innerWidth}
                    height={bar.height}
                    className='fill-slate-100'
                    rx={6}
                    ry={6}
                  />
                </g>
              ))}
            </>
          ),
          'grid',
          'axes',
          'bars',
          'markers',
          'legends',
          'annotations',
        ]}
      />
    </div>
  )
})

export default TopBrowsersChart
