// app/javascript/components/CampaignAnalytics.jsx
import React from 'react';

const CampaignDetails = ({ campaign }) => (
 <div className="lg:col-span-2 bg-white rounded-xl shadow-sm border border-slate-200 overflow-hidden">
   <div className="px-6 py-4 border-b border-slate-200 bg-gradient-to-r from-slate-800 to-slate-700">
     <h3 className="text-sm font-medium text-white">Campaign Details</h3>
   </div>
   <div className="p-6">
     <dl className="grid grid-cols-2 gap-x-6 gap-y-4">
       <div>
         <dt className="text-sm text-slate-500">Send Time</dt>
         <dd className="mt-1 flex items-center gap-1.5">
           {campaign.send_time === 'later' ? (
             <span className="inline-flex items-center gap-1 px-2 py-1 rounded-md text-sm font-medium bg-amber-50 text-amber-700">
               <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
               </svg>
               {campaign.scheduled_time}
             </span>
           ) : (
             <span className="inline-flex items-center gap-1 px-2 py-1 rounded-md text-sm font-medium bg-emerald-50 text-emerald-700">
               <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"/>
               </svg>
               Immediate
             </span>
           )}
         </dd>
       </div>

       <div>
         <dt className="text-sm text-slate-500">Gateway</dt>
         <dd className="mt-1 text-sm text-slate-900">{campaign.sender_name}</dd>
       </div>

       {!campaign.manual_ids && (
         <>
           <div>
             <dt className="text-sm text-slate-500">Target Country</dt>
             <dd className="mt-1 text-sm text-slate-900">{campaign.country}</dd>
           </div>

           {campaign.last_deposit_start && campaign.last_deposit_end && (
             <>
               <div>
                 <dt className="text-sm text-slate-500">Last Deposit Period</dt>
                 <dd className="mt-1 text-sm text-slate-900">
                   {new Date(campaign.last_deposit_start).toLocaleDateString('en-US', { 
                     month: 'short', 
                     day: 'numeric' 
                   })} - {new Date(campaign.last_deposit_end).toLocaleDateString('en-US', { 
                     month: 'short', 
                     day: 'numeric',
                     year: 'numeric' 
                   })}
                 </dd>
               </div>

               <div>
                 <dt className="text-sm text-slate-500">Min. Deposits</dt>
                 <dd className="mt-1 text-sm text-slate-900">{campaign.deposits_count}</dd>
               </div>
             </>
           )}
         </>
       )}

    <div>
        <dt className="text-sm text-slate-500">Created</dt>
        <dd className="mt-1 text-sm text-slate-900">
            {new Date(campaign.created_at).toLocaleDateString('en-US', { 
            month: 'short', 
            day: 'numeric',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
            })}
        </dd>
    </div>

    {campaign.short_url && (
        <div>
            <dt className="text-sm text-slate-500">Short URL Info</dt>
            <dd className="mt-1 text-sm">
            <div className="flex items-center gap-2">
                <a 
                href={`https://${campaign.short_url}`} 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
                >
                {campaign.short_url}
                </a>
                <button
                onClick={() => navigator.clipboard.writeText(`https://${campaign.short_url}`)}
                className="p-1 text-slate-400 hover:text-slate-600"
                title="Copy URL"
                >
                <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                    d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2"/>
                </svg>
                </button>
            </div>
            <div className="mt-1 text-xs text-slate-500">
                ID: {campaign.rebrandly_id}<br/>
                Destination: {campaign.destination_url}
            </div>
            </dd>
        </div>
    )}
    </dl>
   </div>
 </div>
);

const MessagePreview = ({ campaign }) => (
 <div className="lg:col-span-1">
   <div className="bg-white rounded-xl shadow-sm border border-slate-200 overflow-hidden">
     <div className="p-4">
       <div className="bg-slate-100 rounded-lg overflow-hidden">
         <div className="px-4 py-2.5 border-b border-slate-200 flex items-center gap-3">
           <div className="w-8 h-8 rounded-full bg-gradient-to-br from-blue-500 to-blue-600 flex items-center justify-center">
             <span className="text-sm font-medium text-white">P</span>
           </div>
           <div>
             <div className="text-sm font-medium text-slate-900">PariPesa</div>
             <div className="text-xs text-slate-500">via {campaign.sender_name}</div>
           </div>
         </div>
         <div className="p-4 bg-white">
           <p className="text-sm text-slate-900 whitespace-pre-wrap">{campaign.message_text}</p>
           {campaign.message_link && (
             <a 
               href={campaign.message_link} 
               target="_blank"
               rel="noopener noreferrer"
               className="mt-2 inline-block text-sm text-blue-600 hover:underline"
             >
               {campaign.message_link}
             </a>
           )}
         </div>
       </div>
     </div>
   </div>
 </div>
);

const CampaignAnalytics = ({ campaign }) => {
 return (
   <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
     <CampaignDetails campaign={campaign} />
     <MessagePreview campaign={campaign} />
   </div>
 );
};

export default CampaignAnalytics;