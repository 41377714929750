import { Monitor } from 'lucide-react'

export const getCountryCode = (location) => {
  const parts = location.split(' - ')
  return parts.length > 1 ? parts[1].trim().toLowerCase() : 'un'
}

export const getCityName = (location) => {
  const parts = location.split(' - ')
  return parts[0].replace(/-/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())
}

export const getDisplayName = (location) => {
  const countryCode = getCountryCode(location)
  const cityName = getCityName(location)
  return `${cityName}, ${countryCode.toUpperCase()}`
}

export const maxPercentage = 100

export const browserMap = {
  chrome: {
    name: 'Chrome',
    icon: (
      <img
        src='/google-chrome.svg'
        alt='google-chrome'
        className='inline-block mr-2'
      />
    ),
  },
  'chrome-mobile': {
    name: 'Chrome Mobile',
    icon: (
      <img
        src='/google-chrome.svg'
        alt='google-chrome'
        className='inline-block mr-2'
      />
    ),
  },
  firefox: {
    name: 'Firefox',
    icon: (
      <img src='/firefox.png' alt='firefox' className='inline-block mr-2' />
    ),
  },
  'firefox-mobile': {
    name: 'Firefox Mobile',
    icon: (
      <img src='/firefox.png' alt='firefox' className='inline-block mr-2' />
    ),
  },
  safari: {
    name: 'Safari',
    icon: <img src='/safari.svg' alt='safari' className='inline-block mr-2' />,
  },
  'safari-mobile': {
    name: 'Safari Mobile',
    icon: <img src='/safari.svg' alt='safari' className='inline-block mr-2' />,
  },
  'microsoft-edge-mobile': {
    name: 'Edge Mobile',
    icon: <img src='/edge.svg' alt='edge' className='inline-block mr-2' />,
  },
  'opera-mobile': {
    name: 'Opera Mobile',
    icon: <img src='/opera.svg' alt='opera' className='inline-block mr-2' />,
  },
  'android-webview': {
    name: 'Android Webview',
    icon: (
      <img src='/android.svg' alt='android' className='inline-block mr-2' />
    ),
  },
  'mobile-samsung-browser': {
    name: 'Mobile Samsung Browser',
    icon: (
      <img
        src='/mobile-samsung.svg'
        alt='mobile-samsung'
        className='inline-block mr-2'
      />
    ),
  },
  'miui-browser': {
    name: 'Miui Browser',
    icon: <img src='/miui.png' alt='miui' className='inline-block mr-2' />,
  },
  'phoenix-browser': {
    name: 'Phoenix Browser',
    icon: <img src='/fenix.png' alt='fenix' className='inline-block mr-2' />,
  },
  'vivo-browser': {
    name: 'Vivo Browser',
    icon: (
      <img src='/browser.png' alt='browser' className='inline-block mr-2' />
    ),
  },
  whatsapp: {
    name: 'Whatsapp',
    icon: (
      <img src='/whatsapp.svg' alt='whatsapp' className='inline-block mr-2' />
    ),
  },
  'yandex.browser-mobile': {
    name: 'Yandex.Browser Mobile',
    icon: <img src='/yandex.png' alt='yandex' className='inline-block mr-2' />,
  },
  'huawei-browser-mobile': {
    name: 'Huawei Browser Mobile',
    icon: <img src='/huawei.png' alt='huawei' className='inline-block mr-2' />,
  },
  'firefox-for-ios': {
    name: 'Firefox For Ios',
    icon: (
      <img src='/firefox.png' alt='firefox' className='inline-block mr-2' />
    ),
  },
  'heytap-browser': {
    name: 'Heytap Browser',
    icon: (
      <img src='/browser.png' alt='browser' className='inline-block mr-2' />
    ),
  },
  others: {
    name: 'Others',
    icon: (
      <img src='/browser.png' alt='browser' className='inline-block mr-2' />
    ),
  },
}

export const platformMap = {
  android: {
    name: 'Android',
    icon: (
      <img src='/android.svg' alt='android' className='inline-block mr-2' />
    ),
  },
  ios: {
    name: 'iOS',
    icon: <img src='/ios.svg' alt='ios' className='inline-block mr-2' />,
  },
  linux: {
    name: 'Linux',
    icon: <img src='/linux.png' alt='linux' className='inline-block mr-2' />,
  },
  macos: {
    name: 'macOS',
    icon: <img src='/macos.svg' alt='macos' className='inline-block mr-2' />,
  },
  windows: {
    name: 'Windows',
    icon: (
      <img src='/windows.svg' alt='windows' className='inline-block mr-2' />
    ),
  },
  others: {
    name: 'Others',
    icon: <Monitor size={16} className='inline-block mr-2' />,
  },
}
