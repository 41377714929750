import React, { useState } from 'react';

const CampaignCard = ({ campaign }) => {
  const [status, setStatus] = useState(campaign.status);
  const [isLoading, setIsLoading] = useState(false);

  const { total, sent, failed, pending } = campaign.stats;
  const sentPercentage = total > 0 ? Math.round((sent / total) * 100) : 0;
  
  // Добавляем отображение кликов, если они есть
  const showClicks = campaign.has_analytics && campaign.total_clicks !== null;
  const totalClicks = campaign.total_clicks || 0;
  
  // Форматирование статуса
  const statusMap = {
    pending: { label: 'Pending', color: 'bg-slate-100 text-slate-600', icon: (
      <svg className="w-3.5 h-3.5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    )},
    running: { label: 'Sending', color: 'bg-blue-50 text-blue-600', icon: (
      <svg className="w-3.5 h-3.5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
      </svg>
    )},
    paused: { label: 'Paused', color: 'bg-amber-50 text-amber-600', icon: (
      <svg className="w-3.5 h-3.5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    )},
    completed: { label: 'Completed', color: 'bg-emerald-50 text-emerald-600', icon: (
      <svg className="w-3.5 h-3.5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
      </svg>
    )}
  };
  
  const statusInfo = statusMap[status] || statusMap.pending;

  const handlePause = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`/api/v1/campaigns/${campaign.id}/pause`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        }
      });
      const data = await response.json();
      setStatus(data.status);
    } catch (error) {
      console.error('Error pausing campaign:', error);
    }
    setIsLoading(false);
  };

  const handleResume = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`/api/v1/campaigns/${campaign.id}/resume`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        }
      });
      const data = await response.json();
      setStatus(data.status);
    } catch (error) {
      console.error('Error resuming campaign:', error);
    }
    setIsLoading(false);
  };

  return (
    <div className="bg-white rounded-lg border border-slate-200 shadow-sm hover:shadow transition-all duration-200">
      <div className="px-4 py-3">
        {/* Верхняя строка: название, статус */}
        <div className="flex items-center mb-3">
          <h3 className="text-base font-medium text-slate-900 truncate mr-2">
            <a href={`/campaigns/${campaign.id}`} className="hover:text-blue-600">
              {campaign.name}
            </a>
          </h3>
          <div className={`inline-flex items-center px-2 py-0.5 text-xs font-medium rounded ${statusInfo.color}`}>
            {statusInfo.icon}
            {statusInfo.label}
          </div>
          
          {status === 'running' && (
            <div className="flex items-center ml-2">
              <span className="relative flex h-2 w-2 mr-1">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-emerald-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-2 w-2 bg-emerald-500"></span>
              </span>
              <span className="text-xs text-emerald-600">Active</span>
            </div>
          )}
        </div>
        
        {/* Информация о кампании в одну линию */}
        <div className="flex items-center justify-between mb-2">
          {/* Левая часть: информация о рассылке */}
          <div className="flex items-center w-[30%]">
            <div className="text-xs text-slate-500 mr-4">
              {campaign.sender_name || 'No sender'}
            </div>
            
            {campaign.manual_ids ? (
              <div className="text-xs text-violet-600">
                Manual List • {total} recipients
              </div>
            ) : (
              <div className="text-xs text-blue-600">
                {campaign.country} • {total} recipients
              </div>
            )}
          </div>
          
          {/* Средняя часть: прогресс и статистика */}
          <div className="flex items-center justify-center space-x-6 w-[40%]">
            {/* Прогресс */}
            <div className="flex items-center">
              <div className="text-xs text-slate-500 mr-1">Progress</div>
              <div className="text-xs text-slate-500 ml-1">{sentPercentage}%</div>
            </div>
            
            {/* Статистика */}
            <div className="flex items-center space-x-3">
              <div className="flex items-center">
                <div className="w-2 h-2 rounded-full bg-emerald-500 mr-1.5"></div>
                <span className="text-xs text-slate-700">{sent}</span>
                <span className="text-xs text-slate-500 ml-1">sent</span>
              </div>
              
              <div className="flex items-center">
                <div className="w-2 h-2 rounded-full bg-blue-500 mr-1.5"></div>
                <span className="text-xs text-slate-700">{pending}</span>
                <span className="text-xs text-slate-500 ml-1">pending</span>
              </div>
              
              {showClicks && (
                <div className="flex items-center">
                  <div className="w-2 h-2 rounded-full bg-indigo-500 mr-1.5"></div>
                  <span className="text-xs text-slate-700">{totalClicks}</span>
                  <span className="text-xs text-slate-500 ml-1">clicks</span>
                </div>
              )}
            </div>
          </div>
          
          {/* Правая часть: кнопки */}
          <div className="flex items-center justify-end w-[30%]">
            {status === 'running' && (
              <button
                onClick={handlePause}
                disabled={isLoading}
                className={`p-1.5 text-amber-600 bg-amber-50 hover:bg-amber-100 rounded-md transition-colors ${isLoading ? 'opacity-50' : ''}`}
                title="Pause Campaign"
              >
                <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </button>
            )}
            
            {status === 'paused' && (
              <button
                onClick={handleResume}
                disabled={isLoading}
                className={`p-1.5 text-emerald-600 bg-emerald-50 hover:bg-emerald-100 rounded-md transition-colors ${isLoading ? 'opacity-50' : ''}`}
                title="Resume Campaign"
              >
                <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </button>
            )}
            
            <a
              href={`/campaigns/${campaign.id}`}
              className="inline-flex items-center p-1.5 text-slate-500 hover:bg-slate-100 rounded-md transition-colors ml-1"
              title="View Campaign Details"
            >
              <svg className="w-4 h-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
              </svg>
              <span className="text-xs">Details</span>
            </a>
          </div>
        </div>
        
        {/* Визуальный прогрессбар */}
        <div className="h-2 bg-slate-100 rounded-full overflow-hidden">
          <div 
            className={`h-full rounded-full transition-all duration-500 ${
              status === 'completed' ? 'bg-emerald-500' : 'bg-blue-500'
            }`}
            style={{ width: `${sentPercentage}%` }}
          ></div>
        </div>
        
        {/* Время создания */}
	<div className="text-xs text-slate-500 mt-2">
  Created {campaign.created_at_ago}
  {status === 'completed' && campaign.completion_time && (
    <>
      <span className="mx-2">•</span>
      <span>Completed {campaign.completion_time}</span>
    </>
  )}
</div>
      </div>
    </div>
  );
};

export default CampaignCard;
