import React, { useEffect, useMemo } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { getCountryCode, getDisplayName, maxPercentage } from './Helpers'
import Flag from 'react-world-flags'

const CountryRegionsChart = React.memo(({ data }) => {
  const [showAll, setShowAll] = React.useState(false)
  const [sortedData, setSortedData] = React.useState([])

  useEffect(() => {
    const processedData = Object.entries(data)
      .map(([region, details]) => ({
        name: getDisplayName(region),
        percentage: details.percentage,
        countryCode: getCountryCode(region).toUpperCase(),
        weight: details.weight,
        estimated_clicks: details.estimated_clicks,
      }))
      .sort((a, b) => b.percentage - a.percentage)

    setSortedData(processedData)
  }, [data])

  const displayedData = showAll
    ? [...sortedData].reverse()
    : [...sortedData.slice(0, 5)].reverse()

  const axisLeft = useMemo(
    () => ({
      tickSize: 0,
      tickPadding: 10,
      tickRotation: 0,
      renderTick: (tick) => {
        const entry = displayedData.find((d) => d.name === tick.value)
        if (!entry) return null

        return (
          <g transform={`translate(${tick.x - 170},${tick.y})`}>
            <foreignObject x='-22' y='-8' width='18' height='12'>
              <Flag
                code={entry.countryCode}
                style={{ width: '100%', height: '100%' }}
              />
            </foreignObject>
            <text
              x='7'
              y='0'
              dy={4}
              fill='#334155'
              textAnchor='start'
              fontSize='14'
            >
              {tick.value}
            </text>
          </g>
        )
      },
    }),
    [displayedData]
  )

  return (
    <div
      className='bg-white text-slate-900 border border-slate-200 p-6 rounded-lg shadow-sm w-full'
      style={{ height: showAll ? '650px' : '300px' }}
    >
      <div className='flex justify-between items-center'>
        <h3 className='text-lg font-semibold text-slate-900'>Top Regions</h3>
        <button
          onClick={() => setShowAll(!showAll)}
          className='bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700'
        >
          {showAll ? 'Hide' : 'Show All'}
        </button>
      </div>
      <ResponsiveBar
        data={displayedData}
        keys={['percentage']}
        animate={true}
        indexBy='name'
        margin={{ top: 0, right: 60, bottom: 50, left: 195 }}
        padding={0.3}
        layout='horizontal'
        colors={'#2563EB'}
        labelTextColor='transparent'
        labelSkipWidth={999}
        labelSkipHeight={999}
        maxValue={maxPercentage}
        borderRadius={6}
        axisTop={null}
        axisRight={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          tickColor: '#64748B',
          format: (value) => {
            const entry = displayedData.find((d) => d.name === value)
            return entry ? `${entry.percentage.toFixed(2)}%` : ''
          },
        }}
        axisBottom={null}
        enableGridY={false}
        theme={{
          tooltip: {
            container: {
              background: '#2563EB',
              color: 'white',
              fontSize: 14,
              borderRadius: 4,
              padding: '6px 12px',
            },
          },
          labels: {
            text: {
              fontSize: 14,
              fill: '#334155',
            },
          },
          axis: {
            ticks: {
              text: {
                fill: '#334155',
                fontSize: 14,
              },
            },
          },
        }}
        axisLeft={axisLeft}
        tooltip={({ indexValue, value }) => {
          const entry = displayedData.find((d) => d.name === indexValue)

          return (
            <div className='bg-white text-slate-900 text-sm px-3 py-1 rounded shadow-md border border-slate-300 flex flex-col justify-center items-center'>
              <strong>{indexValue}</strong>
              <div> Percentage: {value.toFixed(2)}%</div>
              <div> Weight: {entry.weight.toFixed(4)}</div>
              <div> Clicks: {entry.estimated_clicks}</div>
            </div>
          )
        }}
        layers={[
          (props) => (
            <>
              {props.bars.map((bar) => {
                return (
                  <g key={bar.key}>
                    <rect
                      x={bar.x}
                      y={bar.y}
                      width={props.innerWidth}
                      height={bar.height}
                      className='fill-slate-100'
                      rx={6}
                      ry={6}
                    />
                  </g>
                )
              })}
            </>
          ),
          'grid',
          'axes',
          'bars',
          'markers',
          'legends',
          'annotations',
        ]}
      />
    </div>
  )
})

export default CountryRegionsChart
