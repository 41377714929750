import React, { useState, useEffect, useRef } from 'react';
import CampaignCard from './CampaignCard';

const CampaignList = ({ initialCampaigns, initialStats }) => {
  const [activeTab, setActiveTab] = useState('all');
  const [campaigns, setCampaigns] = useState(initialCampaigns || []);
  const [stats, setStats] = useState(initialStats || {
    total: 0,
    delivered: 0,
    pending: 0,
    failed: 0
  });
  
  // Tracking which campaigns are actively sending
  const activeCampaignIds = campaigns
    .filter(c => c.status === 'running')
    .map(c => c.id);
  
  // Reference to store polling timer
  const pollingTimerRef = useRef(null);
  
  // Function to fetch updated campaign data
  const fetchUpdates = async () => {
    if (activeCampaignIds.length === 0) return;
    
    try {
      const response = await fetch('/api/v1/campaigns/stats', {
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        setCampaigns(prevCampaigns => 
          prevCampaigns.map(campaign => {
            const updatedCampaign = data.campaigns.find(c => c.id === campaign.id);
            return updatedCampaign ? { ...campaign, ...updatedCampaign } : campaign;
          })
        );
        setStats(data.stats);
      }
    } catch (error) {
      console.error('Error fetching campaign updates:', error);
    }
  };
  
  // Setup and cleanup polling
  useEffect(() => {
    // Only start polling if there are active campaigns
    if (activeCampaignIds.length > 0) {
      fetchUpdates(); // Fetch immediately
      pollingTimerRef.current = setInterval(fetchUpdates, 2000);
    }
    
    return () => {
      if (pollingTimerRef.current) {
        clearInterval(pollingTimerRef.current);
      }
    };
  }, [activeCampaignIds.join(',')]);

  const tabCounts = {
    all: campaigns.length,
    inProgress: campaigns.filter(c => c.stats.pending > 0).length,
    completed: campaigns.filter(c => c.stats.total === c.stats.sent).length,
    scheduled: campaigns.filter(c => c.send_time === 'later').length
  };

  const getFilteredCampaigns = () => {
    switch (activeTab) {
      case 'inProgress':
        return campaigns.filter(c => c.stats.pending > 0);
      case 'completed':
        return campaigns.filter(c => c.stats.total === c.stats.sent);
      case 'scheduled':
        return campaigns.filter(c => c.send_time === 'later');
      default:
        return campaigns;
    }
  };

  return (
    <div className="min-h-screen bg-slate-50">
      <div className="max-w-[1400px] mx-auto px-4 sm:px-6 lg:px-8 py-6">
        {/* Основной заголовок и кнопка */}
        <div className="flex items-center justify-between mb-6">
          <div>
            <h1 className="text-xl font-semibold text-slate-900">Campaigns</h1>
            <p className="mt-1 text-sm text-slate-500">Manage and track your messaging campaigns</p>
          </div>
          
          <a 
            href="/campaigns/new" 
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-blue-600 rounded hover:bg-blue-700 transition-colors shadow-sm"
          >
            <svg className="w-4 h-4 mr-1.5" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
            </svg>
            New Campaign
          </a>
        </div>
        
        {/* Табы */}
        <div className="flex items-center border-b border-slate-200 mb-5">
          <button
            onClick={() => setActiveTab('all')}
            className={`relative inline-flex items-center px-1 py-3 border-b-2 font-medium text-sm transition-colors duration-200 ease-out ${
              activeTab === 'all'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-slate-500 hover:text-slate-700 hover:border-slate-300'
            }`}
          >
            <svg className="w-5 h-5 mr-1.5" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
            </svg>
            All
            <span className="ml-1.5 px-1.5 py-0.5 text-xs rounded-full bg-slate-100 text-slate-600">{tabCounts.all}</span>
          </button>
          
          <button
            onClick={() => setActiveTab('inProgress')}
            className={`relative inline-flex items-center px-1 py-3 border-b-2 font-medium text-sm transition-colors duration-200 ease-out ml-8 ${
              activeTab === 'inProgress'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-slate-500 hover:text-slate-700 hover:border-slate-300'
            }`}
          >
            <svg className="w-5 h-5 mr-1.5" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
            </svg>
            In Progress
            <span className="ml-1.5 px-1.5 py-0.5 text-xs rounded-full bg-slate-100 text-slate-600">{tabCounts.inProgress}</span>
          </button>
          
          <button
            onClick={() => setActiveTab('completed')}
            className={`relative inline-flex items-center px-1 py-3 border-b-2 font-medium text-sm transition-colors duration-200 ease-out ml-8 ${
              activeTab === 'completed'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-slate-500 hover:text-slate-700 hover:border-slate-300'
            }`}
          >
            <svg className="w-5 h-5 mr-1.5" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Completed
            <span className="ml-1.5 px-1.5 py-0.5 text-xs rounded-full bg-slate-100 text-slate-600">{tabCounts.completed}</span>
          </button>
          
          <button
            onClick={() => setActiveTab('scheduled')}
            className={`relative inline-flex items-center px-1 py-3 border-b-2 font-medium text-sm transition-colors duration-200 ease-out ml-8 ${
              activeTab === 'scheduled'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-slate-500 hover:text-slate-700 hover:border-slate-300'
            }`}
          >
            <svg className="w-5 h-5 mr-1.5" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Scheduled
            <span className="ml-1.5 px-1.5 py-0.5 text-xs rounded-full bg-slate-100 text-slate-600">{tabCounts.scheduled}</span>
          </button>
        </div>
        
        {/* Список кампаний */}
        <div className="space-y-4">
          {getFilteredCampaigns().map(campaign => (
            <CampaignCard key={campaign.id} campaign={campaign} />
          ))}
          
          {getFilteredCampaigns().length === 0 && (
            <div className="bg-white rounded-lg border border-slate-200 p-8 text-center">
              <div className="flex justify-center">
                <div className="p-3 rounded-full bg-slate-100 text-slate-500 inline-block">
                  <svg className="w-8 h-8" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                  </svg>
                </div>
              </div>
              <h3 className="mt-4 text-lg font-medium text-slate-900">No campaigns found</h3>
              <p className="mt-2 text-sm text-slate-500">
                {activeTab === 'all' 
                  ? "Start by creating your first campaign to send messages to your customers." 
                  : `No ${activeTab} campaigns found at the moment.`}
              </p>
              <div className="mt-6">
                <a
                  href="/campaigns/new"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <svg className="w-4 h-4 mr-1.5" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                  </svg>
                  Create New Campaign
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CampaignList;