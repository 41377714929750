import React, { useState } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import dayjs from 'dayjs'
import DatePicker from 'react-datepicker'

const SmsAnalyticsChart = ({ analytics, sentCustomers }) => {
  const [filter, setFilter] = useState('week')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const today = dayjs().format('YYYY-MM-DD')
  const weekStart = dayjs().startOf('week').format('YYYY-MM-DD')
  const weekEnd = dayjs().endOf('week').format('YYYY-MM-DD')
  const monthStart = dayjs().subtract(30, 'days').format('YYYY-MM-DD')

  let filteredData = Object.entries(analytics?.by_day || {})

  if (startDate && endDate) {
    const formattedStart = dayjs(startDate).format('YYYY-MM-DD')
    const formattedEnd = dayjs(endDate).format('YYYY-MM-DD')
    filteredData = filteredData.filter(
      ([date]) => date >= formattedStart && date <= formattedEnd
    )
  } else {
    if (filter === 'today') {
      filteredData = filteredData.filter(([date]) => date === today)
    } else if (filter === 'week') {
      filteredData = filteredData.filter(
        ([date]) => date >= weekStart && date <= weekEnd
      )
    } else if (filter === 'month') {
      filteredData = filteredData.filter(([date]) => date >= monthStart)
    }
  }

  const chartData = filteredData.map(([date, clicks]) => ({
    date,
    formattedDate: dayjs(date).format('DD.MM'),
    clicks,
  }))

  const availableDates = new Set(Object.keys(analytics?.by_day || {}))

  const totalClicks = analytics.total_clicks || 0
  const totalSentSMS = sentCustomers.length
  const totalConversionRate = totalSentSMS
    ? (totalClicks / totalSentSMS) * 100
    : 0

  const isDatePickerActive = startDate !== null || endDate !== null

  return (
    <div className='bg-white text-slate-900 p-6 rounded-lg shadow-sm border border-slate-200'>
      <div className='flex justify-between items-center mb-4'>
        <h3 className='text-xl font-semibold'>SMS Campaign</h3>
        <div className='flex space-x-2'>
          <div className='flex items-center'>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              minDate={dayjs([...availableDates][0]).toDate()}
              maxDate={dayjs(
                [...availableDates][availableDates.size - 1]
              ).toDate()}
              filterDate={(date) =>
                availableDates.has(dayjs(date).format('YYYY-MM-DD'))
              }
              className='px-4 py-2 bg-white text-gray-700 rounded border border-gray-300 focus:border-blue-500'
              placeholderText='Start Date'
              dateFormat='dd.MM.yyyy'
              isClearable
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              maxDate={dayjs(
                [...availableDates][availableDates.size - 1]
              ).toDate()}
              filterDate={(date) =>
                availableDates.has(dayjs(date).format('YYYY-MM-DD'))
              }
              className='px-4 py-2 bg-white ml-2 text-gray-700 rounded border border-gray-300 focus:border-blue-500'
              placeholderText='End Date'
              dateFormat='dd.MM.yyyy'
              isClearable
            />
          </div>
          {['today', 'week', 'month'].map((period) => (
            <button
              key={period}
              onClick={() => {
                setFilter(period)
                setStartDate(null)
                setEndDate(null)
              }}
              className={`px-4 py-2 rounded transition-colors ${
                isDatePickerActive
                  ? 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                  : filter === period
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
              }`}
            >
              {period === 'today'
                ? 'Day'
                : period === 'week'
                ? 'Week'
                : 'Month'}
            </button>
          ))}
        </div>
      </div>

      <div className='grid grid-cols-3 gap-4 mb-6'>
        <div className='bg-gray-100 p-4 rounded-lg text-center'>
          <h4 className='text-lg font-semibold text-slate-900'>Total Clicks</h4>
          <p className='text-2xl font-bold'>{totalClicks}</p>
        </div>
        <div className='bg-gray-100 p-4 rounded-lg text-center'>
          <h4 className='text-lg font-semibold text-slate-900'>
            Total Sent SMS
          </h4>
          <p className='text-2xl font-bold'>{totalSentSMS}</p>
        </div>
        <div className='bg-gray-100 p-4 rounded-lg text-center'>
          <h4 className='text-lg font-semibold text-slate-900'>
            Total Conversion Rate
          </h4>
          <p className='text-2xl font-bold'>{totalConversionRate}%</p>
        </div>
      </div>

      <div className='h-64'>
        <ResponsiveBar
          data={chartData}
          keys={['clicks']}
          indexBy='formattedDate'
          margin={{ top: 20, right: 30, bottom: 30, left: 30 }}
          padding={0.3}
          colors={'#2563EB'}
          axisBottom={{
            tickSize: 0,
            tickPadding: 5,
            tickRotation: filter === 'month' ? -45 : 0,
            tickTextColor: '#334155',
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 5,
            tickTextColor: '#334155',
          }}
          enableLabel={false}
          tooltip={({ indexValue, value }) => (
            <div className='bg-white text-gray-900 text-sm px-3 py-1 rounded border border-gray-300 shadow-md'>
              <strong>{indexValue}</strong> | Clicks: <strong>{value}</strong>
            </div>
          )}
        />
      </div>
    </div>
  )
}

export default SmsAnalyticsChart
